import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './errors/pageNotFound.component';
var ɵ0 = { title: '404' }, ɵ1 = { title: '404' };
var routes = [
    {
        path: 'evx-downloads-Plus',
        loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
    },
    {
        path: 'evx-downloads',
        loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
    },
    {
        path: 'downloads',
        loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
    },
    {
        path: 'luminapc-downloads',
        loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
    },
    { path: 'pagenotfound', component: PageNotFoundComponent, pathMatch: 'full', data: ɵ0 },
    { path: '**', component: PageNotFoundComponent, data: ɵ1 }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
